import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <>
      <section className="footermain">
        <div className="footerleft">
          <img src="/logo.svg" alt="logoimg" className="logoimg" />
          <div className="footerlinks">
            <a href="/" className="footerinnerlink">
              Privacy Policy
            </a>
            <a href="/" className="footerinnerlink">
              Terms and Service
            </a>
            <a href="mailto:kwesi@powershop.link" className="footerinnerlink">
              Contact Us: kwesi@powershop.link
            </a>
          </div>
        </div>
        <div className="footerright">
          <a href="#" className="sociallink" target="_blank">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="socialimg"
            >
              <path
                d="M6.96 0.833984H17.04C20.88 0.833984 24 3.95398 24 7.79398V17.874C24 19.7199 23.2667 21.4902 21.9615 22.7954C20.6562 24.1007 18.8859 24.834 17.04 24.834H6.96C3.12 24.834 0 21.714 0 17.874V7.79398C0 5.94808 0.733284 4.17777 2.03854 2.87252C3.34379 1.56727 5.11409 0.833984 6.96 0.833984ZM6.72 3.23398C5.57427 3.23398 4.47546 3.68913 3.6653 4.49928C2.85514 5.30944 2.4 6.40825 2.4 7.55398V18.114C2.4 20.502 4.332 22.434 6.72 22.434H17.28C18.4257 22.434 19.5245 21.9788 20.3347 21.1687C21.1449 20.3585 21.6 19.2597 21.6 18.114V7.55398C21.6 5.16598 19.668 3.23398 17.28 3.23398H6.72ZM18.3 5.03398C18.6978 5.03398 19.0794 5.19202 19.3607 5.47332C19.642 5.75463 19.8 6.13616 19.8 6.53398C19.8 6.93181 19.642 7.31334 19.3607 7.59465C19.0794 7.87595 18.6978 8.03398 18.3 8.03398C17.9022 8.03398 17.5206 7.87595 17.2393 7.59465C16.958 7.31334 16.8 6.93181 16.8 6.53398C16.8 6.13616 16.958 5.75463 17.2393 5.47332C17.5206 5.19202 17.9022 5.03398 18.3 5.03398ZM12 6.83398C13.5913 6.83398 15.1174 7.46613 16.2426 8.59134C17.3679 9.71656 18 11.2427 18 12.834C18 14.4253 17.3679 15.9514 16.2426 17.0766C15.1174 18.2018 13.5913 18.834 12 18.834C10.4087 18.834 8.88258 18.2018 7.75736 17.0766C6.63214 15.9514 6 14.4253 6 12.834C6 11.2427 6.63214 9.71656 7.75736 8.59134C8.88258 7.46613 10.4087 6.83398 12 6.83398ZM12 9.23398C11.0452 9.23398 10.1295 9.61327 9.45442 10.2884C8.77928 10.9635 8.4 11.8792 8.4 12.834C8.4 13.7888 8.77928 14.7044 9.45442 15.3796C10.1295 16.0547 11.0452 16.434 12 16.434C12.9548 16.434 13.8705 16.0547 14.5456 15.3796C15.2207 14.7044 15.6 13.7888 15.6 12.834C15.6 11.8792 15.2207 10.9635 14.5456 10.2884C13.8705 9.61327 12.9548 9.23398 12 9.23398Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="#" className="sociallink" target="_blank">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="socialimg"
            >
              <mask
                id="mask0_48_269"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="25"
              >
                <path d="M0 0.833984H24V24.834H0V0.833984Z" fill="white" />
              </mask>
              <g mask="url(#mask0_48_269)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 12.901C0 18.868 4.333 23.828 10 24.834V16.167H7V12.834H10V10.167C10 7.16698 11.933 5.50098 14.667 5.50098C15.533 5.50098 16.467 5.63398 17.333 5.76698V8.83398H15.8C14.333 8.83398 14 9.56698 14 10.501V12.834H17.2L16.667 16.167H14V24.834C19.667 23.828 24 18.868 24 12.901C24 6.26398 18.6 0.833984 12 0.833984C5.4 0.833984 0 6.26398 0 12.901Z"
                  fill="white"
                />
              </g>
            </svg>
          </a>
          <a href="#" className="sociallink" target="_blank">
            <svg
              width="21"
              height="25"
              viewBox="0 0 21 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="socialimg"
            >
              <path
                d="M16.6607 4.59398C15.7452 3.55348 15.2407 2.21723 15.2411 0.833984H11.1027V17.3673C11.0708 18.262 10.6913 19.1095 10.0443 19.7312C9.39731 20.353 8.53319 20.7005 7.63393 20.7007C5.73214 20.7007 4.15179 19.154 4.15179 17.234C4.15179 14.9407 6.375 13.2207 8.66518 13.9273V9.71398C4.04464 9.10065 0 12.674 0 17.234C0 21.674 3.69643 24.834 7.62054 24.834C11.8259 24.834 15.2411 21.434 15.2411 17.234V8.84732C16.9192 10.0471 18.934 10.6908 21 10.6873V6.56732C21 6.56732 18.4821 6.68732 16.6607 4.59398Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="https://x.com/bamboocrypto25"
            className="sociallink"
            target="_blank"
          >
            <svg
              width="29"
              height="24"
              viewBox="0 0 29 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="socialimg"
            >
              <path
                d="M29 3.03987C27.9326 3.5134 26.782 3.82457 25.5899 3.9734C26.8098 3.25634 27.7524 2.11987 28.196 0.753396C27.0454 1.42987 25.7701 1.9034 24.4254 2.17398C23.3303 1.01046 21.7916 0.333984 20.0449 0.333984C16.7873 0.333984 14.1257 2.93163 14.1257 6.1381C14.1257 6.5981 14.1812 7.04457 14.2782 7.46398C9.34321 7.22046 4.94885 4.90693 2.0239 1.40281C1.51099 2.25516 1.21989 3.25634 1.21989 4.31163C1.21989 6.32751 2.25956 8.1134 3.86759 9.1281C2.88337 9.1281 1.96845 8.85751 1.16444 8.45163V8.49222C1.16444 11.3063 3.21606 13.6605 5.93308 14.1881C5.06091 14.4221 4.14489 14.4545 3.25765 14.2828C3.63416 15.4362 4.37154 16.4454 5.36613 17.1685C6.36073 17.8917 7.56252 18.2925 8.80258 18.3146C6.70059 19.9388 4.09495 20.8167 1.41396 20.804C0.942639 20.804 0.471319 20.7769 0 20.7228C2.63384 22.3734 5.76673 23.334 9.12142 23.334C20.0449 23.334 26.0473 14.4857 26.0473 6.81457C26.0473 6.55751 26.0473 6.31398 26.0335 6.05692C27.1979 5.24516 28.196 4.21693 29 3.03987Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="https://x.com/bamboocrypto25"
            className="sociallink"
            target="_blank"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="socialimg"
            >
              <path
                d="M12 0.833984C5.376 0.833984 0 6.20998 0 12.834C0 19.458 5.376 24.834 12 24.834C18.624 24.834 24 19.458 24 12.834C24 6.20998 18.624 0.833984 12 0.833984ZM17.568 8.99399C17.388 10.89 16.608 15.498 16.212 17.622C16.044 18.522 15.708 18.822 15.396 18.858C14.7 18.918 14.172 18.402 13.5 17.958C12.444 17.262 11.844 16.83 10.824 16.158C9.636 15.378 10.404 14.946 11.088 14.25C11.268 14.07 14.34 11.274 14.4 11.022C14.4083 10.9838 14.4072 10.9442 14.3968 10.9065C14.3863 10.8689 14.3668 10.8344 14.34 10.806C14.268 10.746 14.172 10.77 14.088 10.782C13.98 10.806 12.3 11.922 9.024 14.13C8.544 14.454 8.112 14.622 7.728 14.61C7.296 14.598 6.48 14.37 5.868 14.166C5.112 13.926 4.524 13.794 4.572 13.374C4.596 13.158 4.896 12.942 5.46 12.714C8.964 11.19 11.292 10.182 12.456 9.70199C15.792 8.30999 16.476 8.06998 16.932 8.06998C17.028 8.06998 17.256 8.09398 17.4 8.21398C17.52 8.30998 17.556 8.44198 17.568 8.53798C17.556 8.60998 17.58 8.82598 17.568 8.99399Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="#" className="sociallink" target="_blank">
            <svg
              width="31"
              height="24"
              viewBox="0 0 31 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="socialimg"
            >
              <path
                d="M26.2299 2.24542C24.2665 1.35441 22.1408 0.707703 19.9264 0.33405C19.907 0.333446 19.8877 0.336993 19.8698 0.344442C19.852 0.351891 19.836 0.363061 19.8231 0.377164C19.5574 0.851415 19.2473 1.46938 19.0407 1.94363C16.692 1.59872 14.3034 1.59872 11.9548 1.94363C11.7481 1.45501 11.4381 0.851415 11.1576 0.377164C11.1428 0.348422 11.0985 0.33405 11.0543 0.33405C8.83991 0.707703 6.7289 1.35441 4.75075 2.24542C4.73599 2.24542 4.72123 2.2598 4.70647 2.27417C0.691118 8.12326 -0.416055 13.8143 0.130151 19.4478C0.130151 19.4765 0.144913 19.5053 0.174437 19.5197C2.83165 21.4167 5.38553 22.5664 7.90989 23.328C7.95417 23.3424 7.99846 23.328 8.01322 23.2993C8.60372 22.5089 9.13516 21.6753 9.59279 20.7987C9.62232 20.7412 9.59279 20.6837 9.53374 20.6694C8.69229 20.3532 7.89513 19.9795 7.11272 19.5484C7.05367 19.5197 7.05367 19.4334 7.09796 19.3903C7.26035 19.2753 7.42273 19.146 7.58512 19.031C7.61464 19.0023 7.65893 19.0023 7.68845 19.0167C12.7667 21.2729 18.2435 21.2729 23.2627 19.0167C23.2922 19.0023 23.3365 19.0023 23.366 19.031C23.5284 19.1604 23.6908 19.2753 23.8532 19.4047C23.9122 19.4478 23.9122 19.534 23.8384 19.5628C23.0708 20.0083 22.2589 20.3676 21.4174 20.6837C21.3584 20.6981 21.3436 20.77 21.3584 20.8131C21.8307 21.6897 22.3622 22.5232 22.9379 23.3137C22.9822 23.328 23.0265 23.3424 23.0708 23.328C25.6099 22.5664 28.1638 21.4167 30.821 19.5197C30.8505 19.5053 30.8653 19.4765 30.8653 19.4478C31.5148 12.9376 29.7876 7.28973 26.289 2.27417C26.2742 2.2598 26.2594 2.24542 26.2299 2.24542ZM10.3604 16.0131C8.83991 16.0131 7.57035 14.6478 7.57035 12.9664C7.57035 11.2849 8.81039 9.91967 10.3604 9.91967C11.9252 9.91967 13.1653 11.2993 13.1505 12.9664C13.1505 14.6478 11.9105 16.0131 10.3604 16.0131ZM20.6498 16.0131C19.1292 16.0131 17.8597 14.6478 17.8597 12.9664C17.8597 11.2849 19.0997 9.91967 20.6498 9.91967C22.2146 9.91967 23.4546 11.2993 23.4398 12.9664C23.4398 14.6478 22.2146 16.0131 20.6498 16.0131Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </section>
    </>
  );
};

export default Footer;
